import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import HamburgerSvg from '/public/gymx/hamburger.svg';
import { logSlice } from '@/store/slice/logSlice';
import { userSlice } from '@/store/slice/userSlice';

import { tokenSelector } from '../../../../store/selector/adminSelector';
import { StoreSelector } from '../StoreSelector';

export const HEADER_HEIGHT = 57;

type Props = {
  showNotification?: boolean;
  toggleDrawer: () => void;
};
export const Header = ({ showNotification, toggleDrawer }: Props) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const tokenState = useSelector(tokenSelector);

  const handleClickHamburger = () => {
    toggleDrawer();
  };

  const goToTop = () => {
    router.push('/');
    dispatch(userSlice.actions.reset());
    dispatch(logSlice.actions.reset());
  };

  const goToLogin = useCallback(() => {
    if (tokenState === '') {
      router.push('/auth/login');
    }
  }, [router, tokenState]);

  useEffect(() => {
    goToLogin();
  }, [goToLogin]);

  return (
    <Container>
      <Flex>
        <Title onClick={goToTop}>
          <TitleImage src="/gymx/title_logo.png" alt="title_logo" width={124} height={31} />
        </Title>
        <StoreSelector isSPView />
      </Flex>
      <ButtonArea>
        <IconBox onClick={handleClickHamburger}>
          <HamburgerSvg />
        </IconBox>
      </ButtonArea>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  background-color: ${({ theme }) => theme.colors.primary};
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.white};
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonArea = styled(Flex)`
  margin-right: 8px;
`;

const Title = styled.div`
  width: 124px;
  margin-left: 8px;
`;

const TitleImage = styled.img`
  vertical-align: bottom;
`;

const IconBox = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
`;
