import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authoritySelector, errorSelector, tokenSelector } from '@/store/selector/adminSelector';
import { adminSlice, onLogin } from '@/store/slice/adminSlice';
import { logSlice } from '@/store/slice/logSlice';
import { userSlice } from '@/store/slice/userSlice';

const useAuth = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const authorities = useSelector(authoritySelector);
  const token = useSelector(tokenSelector);
  const error = useSelector(errorSelector);

  const login = useCallback(
    ({ email, password }: { email: string; password: string }) => {
      const action = onLogin({
        email,
        password,
      });
      dispatch(action);
    },
    [dispatch],
  );

  const logout = useCallback(() => {
    dispatch(adminSlice.actions.reset());
    dispatch(userSlice.actions.reset());
    dispatch(logSlice.actions.reset());
    router.push('/auth/login');
  }, [dispatch, router]);

  return { logout, login, authorities, token, error };
};

export default useAuth;
