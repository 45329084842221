import { SwipeableDrawer } from '@mui/material';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';

import EntranceLogsSvg from '/public/gymx/entrance_logs_1.svg';
import PaymentLogsSvg from '/public/gymx/payment_logs_1.svg';
import SettingSvg from '/public/gymx/setting_1.svg';
import useAuth from '@/hooks/useAuth';
import useGym from '@/hooks/useGym';

import { HEADER_HEIGHT } from './Header';
import { TAB_MENU_HEIGHT } from './TabMenu';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};
export const Drawer = ({ isOpen, onClose }: Props) => {
  const [maxHeight, setMaxHeight] = useState(0);

  const drawerBgColor = 'rgba(99, 99, 99, 0.69)';
  const drawerTop = HEADER_HEIGHT;
  const drawerHeight = useMemo(() => {
    return maxHeight - HEADER_HEIGHT - TAB_MENU_HEIGHT;
  }, [maxHeight]);

  const { logout, authorities } = useAuth();
  const { selectedGym } = useGym();
  const router = useRouter();

  const goToPage = (path: string) => {
    router.push(path);
  };

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const windowElement = document.body;
      const observer = new ResizeObserver((entries) => {
        setMaxHeight(entries[0].contentRect.height);
      });
      if (windowElement) {
        //要素のリサイズを監視
        observer.observe(windowElement);
      }
      //クリーンアップ関数で監視を解く
      return (): void => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <SwipeableDrawer
      anchor="right"
      open={isOpen}
      onOpen={() => null}
      onClose={onClose}
      ModalProps={{
        style: { top: drawerTop, height: drawerHeight },
      }}
      PaperProps={{
        style: {
          top: drawerTop,
          height: drawerHeight - 2,
          boxShadow: 'none',
          borderTop: `1px solid ${drawerBgColor}`,
          borderBottom: `1px solid ${drawerBgColor}`,
        },
      }}
      BackdropProps={{
        style: { top: drawerTop, height: drawerHeight },
      }}>
      <MenuList>
        {authorities.log !== 0 && (
          <div>
            <MenuListItem
              onClick={() => {
                goToPage(`/${selectedGym?.id}/entrances`);
              }}>
              <IconBox>
                <EntranceLogsSvg />
              </IconBox>
              <span>入場履歴</span>
            </MenuListItem>
            <MenuListItem
              onClick={() => {
                goToPage(`/${selectedGym?.id}/payments`);
              }}>
              <IconBox>
                <PaymentLogsSvg />
              </IconBox>
              <span>支払い履歴</span>
            </MenuListItem>
          </div>
        )}
        {authorities.config !== 0 && (
          <div>
            <MenuListItem
              onClick={() => {
                goToPage(`/${selectedGym?.id}/settings`);
              }}>
              <IconBox>
                <SettingSvg />
              </IconBox>
              <span>設定</span>
            </MenuListItem>
          </div>
        )}
        <div>
          <MenuListItem onClick={logout}>
            <span>ログアウト</span>
          </MenuListItem>
        </div>
      </MenuList>
    </SwipeableDrawer>
  );
};

const MenuList = styled.div`
  width: 180px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
`;

const MenuListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
`;

const IconBox = styled.div`
  width: 25px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
`;
